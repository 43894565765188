/*
 * Copyright 2020-2022 Foreseeti AB <https://foreseeti.com>
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     https://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */

import React from 'react';
import ResponsiveContainer from '../components/responsivecontainer';
import { Container, Segment } from 'semantic-ui-react';

const NotFound = () => (
  <ResponsiveContainer>
    <Container>
      <Segment style={{ padding: '8em 0em' }} basic>
        <h1>Oops! Page not found</h1>
      </Segment>
    </Container>
  </ResponsiveContainer>
);

export default NotFound;
